<template>
  <page-container :title="pageTitle" :breadcrumb="breadcrumb">
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="simple-query">
          <a-form-model ref="queryForm" :model="queryParams" layout="inline" class="query-form">
            <a-form-model-item label="公司/项目" prop="monitorpointname" style="width: 25%">
              <a-cascader :field-names="{ label: 'userdepname', value: 'userdepid', children: 'children' }" :options="userInfo.usertype!='0'?deptOptions.filter(item => item.deptype=='1'):deptOptionsAll.filter(item => item.deptype=='1')" v-model="userdepidCascaderSelected" :load-data="userInfo.usertype!='0'?deptOnLoadChildren:deptOnLoadChildrenAll" placeholder="请选择公司/项目" :change-on-select="true" :allow-clear="false"></a-cascader>
            </a-form-model-item>
            <a-form-model-item label="类别" prop="business_format" style="width: 25%">
              <a-select v-model="queryParams.business_format">
                <a-select-option value="地下停车场">地下停车场</a-select-option>
                <a-select-option value="地面停车场">地面停车场</a-select-option>
                <a-select-option value="专用停车场">专用停车场</a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item label="产权人" prop="property_owner" style="width: 25%">
              <a-select v-model="queryParams.property_owner">
                <a-select-option value="开发商">开发商</a-select-option>
                <a-select-option value="企业业主">企业业主</a-select-option>
                <a-select-option value="个人业主">个人业主</a-select-option>
                <a-select-option value="全体业主共有">全体业主共有</a-select-option>
              </a-select>
            </a-form-model-item>
            <div class="query-btns" style="float: right;width: 20%">
              <a-button @click="resetQueryParams">重置</a-button>
              <a-button type="primary" class="after-btn" @click="getTableData(true)">查询</a-button>
              <a-button type="link" @click="showAdvanced=!showAdvanced">展开<a-icon :type="showAdvanced?'up':'down'"></a-icon></a-button>
            </div>
            <div v-show="showAdvanced" class="advanced-query">
              <a-form-model-item label="车位使用形式" prop="parking_type" style="width: 25%">
                <a-select v-model="queryParams.parking_type">
                  <a-select-option value="自用车位">自用车位</a-select-option>
                  <a-select-option value="固定车位">固定车位</a-select-option>
                  <a-select-option value="月租车位">月租车位</a-select-option>
                  <a-select-option value="临租车位">临租车位</a-select-option>
                  <a-select-option value="免费车位">免费车位</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="车位性质" prop="parking_nature" style="width: 25%">
                <a-select v-model="queryParams.parking_nature">
                  <a-select-option value="普通">普通</a-select-option>
                  <a-select-option value="可充电">可充电</a-select-option>
                  <a-select-option value="机械式">机械式</a-select-option>
                  <a-select-option value="自动车库">自动车库</a-select-option>
                </a-select>
              </a-form-model-item>
              <a-form-model-item label="联系人" prop="contacts" style="width: 25%">
                <a-input v-model="queryParams.contacts"></a-input>
              </a-form-model-item>
              <a-form-model-item label="车位号" prop="parking_num" style="width: 25%">
                <a-input v-model="queryParams.parking_num"></a-input>
              </a-form-model-item>
              <a-form-model-item label="车牌号" prop="license_plate" style="width: 25%">
                <a-input v-model="queryParams.license_plate"></a-input>
              </a-form-model-item>
            </div>
          </a-form-model>
        </div>
      </div>
    </div>
    <div class="box-container" style="margin-top: 10px;">
      <div class="box-container-inner">
        <div class="table-header">
          <h3 class="table-title">{{pageTitle}}</h3>
          <div class="table-btns">
<!--            <a-button v-if="btnList.includes('导出')" @click="exportModal" style="margin-right: 10px"><a-icon type="export"></a-icon>批量导出</a-button>-->
            <a-button v-if="btnList.includes('粘贴')" @click="showModal('paste',copyData)" style="margin-right: 10px"><a-icon type="copy"></a-icon>粘贴</a-button>
            <a-button v-if="btnList.includes('新增')" @click="addRecord" style="margin-right: 10px" type="primary"><a-icon type="plus"></a-icon>新增</a-button>
            <a-icon type="redo" class="after-btn table-refresh" @click="getTableData()"></a-icon>
          </div>
        </div>
        <a-table :customRow="customRow" :columns="tableColumns" :data-source="tableData" row-key="ledger_id" :scroll="{ x: 2200 }" :loading="tableLoading" :row-class-name="(record, index) => (index%2==0?'table-row-even':'table-row-single')" style="margin-top: 20px;" :pagination="false">
          <span slot="management_fee_standard" slot-scope="value">{{value}}元/月</span>
          <span slot="monthly_rent_standard" slot-scope="value">{{value}}元/月</span>
          <span slot="paid_in_rent" slot-scope="value">{{value}}元/月</span>
          <span slot="management_fee_arrears" slot-scope="value">{{value}}元</span>
          <span slot="monthly_rent_arrears" slot-scope="value">{{value}}元</span>
          <span slot="operation" slot-scope="value, record">
            <a-dropdown>
              <a class="ant-dropdown-link" @click="e => e.preventDefault()">
                <a-icon type="menu"/>
              </a>
              <a-menu slot="overlay" @click="operationClick">
                <a-menu-item :key="'detail-'+record.ledger_id">详情</a-menu-item>
                <a-menu-item v-if="btnList.includes('修改')" :key="'edit-'+record.ledger_id">修改</a-menu-item>
                                <a-menu-item v-if="btnList.includes('复制')" :key="'copy-'+record.ledger_id">复制</a-menu-item>
                <a-menu-item v-if="btnList.includes('删除')" :key="'delete-'+record.ledger_id">删除</a-menu-item>
              </a-menu>
            </a-dropdown>
          </span>
        </a-table>
        <a-pagination v-model="currentPage" :total="pagination.total" :page-size="pagination.pageSize" show-size-changer @change="pageChange" @showSizeChange="onShowSizeChange" :show-total="(total, range) => `共${total}条`" style="margin-top: 20px; text-align: right;">
          <template slot="buildOptionText" slot-scope="props">
            <span>{{ props.value }}条/页</span>
          </template>
        </a-pagination>
      </div>
    </div>
    <add-or-edit-modal :visible.sync="modalVisible" :show-type="modalShowType" :detailData="modalDetailData" @get-operation-result="getTableData"></add-or-edit-modal>
  </page-container>
</template>
<script>
import moment from "moment";
import {mapGetters, mapState} from "vuex";
import pagination from "@/mixins/pagination";
import {getCache, getItemFromArrayByKey, getTimeRange} from "U/index";
import deptselect from "@/mixins/deptselect";
import addOrEditModal from "V/businessManagement/standingBook/ledgerParking/addOrEditModal";
import {
  deleteLedgerParking,
  getLedgerParkingByCondition,
} from "A/businessmanagement";
export default {
  name: "ledgerParking",
  mixins: [deptselect,pagination],
  components: {
    addOrEditModal,
  },
  data() {
    return {
      moment,
      tableData: [],
      tableLoading: false,
      showAdvanced: false,
      queryParams:{
        monitorpointname:'',
        category:'',
        property_owner:'',
        parking_type:'',
        parking_nature:'',
        contacts:'',
        parking_num:'',
        license_plate:'',
      },
      tableColumns:[
        {
          title: '项目名称',
          dataIndex: 'monitorpointname',
          key: 'monitorpointname',
          ellipsis: true,
        },
        {
          title: '类别',
          dataIndex: 'category',
          key: 'category',
          ellipsis: true,
        },
        {
          title: '产权人',
          dataIndex: 'property_owner',
          key: 'property_owner',
          ellipsis: true,
        },
        {
          title: '车位数',
          dataIndex: 'parking_total',
          key: 'parking_total',
          ellipsis: true,
        },
        {
          title: '车位使用形式',
          dataIndex: 'parking_type',
          key: 'parking_type',
          ellipsis: true,
        },
        {
          title: '车位性质',
          dataIndex: 'parking_nature',
          key: 'parking_nature',
          ellipsis: true,
        },
        {
          title: '车位号',
          dataIndex: 'parking_num',
          key: 'parking_num',
          ellipsis: true,
        },
        {
          title: '车牌号',
          dataIndex: 'license_plate',
          key: 'license_plate',
          ellipsis: true,
        },
        {
          title: '联系人',
          dataIndex: 'contacts',
          key: 'contacts',
          ellipsis: true,
        },
        {
          title: '联系方式',
          dataIndex: 'contacts_phone',
          key: 'contacts_phone',
          ellipsis: true,
        },
        {
          title: '管理费标准',
          dataIndex: 'management_fee_standard',
          key: 'management_fee_standard',
          ellipsis: true,
          scopedSlots: { customRender: 'management_fee_standard' },
        },
        {
          title: '停车费标准',
          dataIndex: 'parking_fee_standard',
          key: 'parking_fee_standard',
          ellipsis: true,
          scopedSlots: { customRender: 'parking_fee_standard' },
        },
        {
          title: '月租金标准',
          dataIndex: 'monthly_rent_standard',
          key: 'monthly_rent_standard',
          ellipsis: true,
          scopedSlots: { customRender: 'monthly_rent_standard' },
        },
        {
          title: '租金折扣',
          dataIndex: 'rent_discount',
          key: 'rent_discount',
          ellipsis: true,
        },
        {
          title: '实收资金',
          dataIndex: 'paid_in_rent',
          key: 'paid_in_rent',
          ellipsis: true,
          scopedSlots: { customRender: 'paid_in_rent' },
        },
        {
          title: '欠管理费金额',
          dataIndex: 'management_fee_arrears',
          key: 'management_fee_arrears',
          ellipsis: true,
          scopedSlots: { customRender: 'management_fee_arrears' },
        },
        {
          title: '欠租金金额',
          dataIndex: 'monthly_rent_arrears',
          key: 'monthly_rent_arrears',
          ellipsis: true,
          scopedSlots: { customRender: 'monthly_rent_arrears' },
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
          ellipsis: true,
        },
        {
          title: '操作',
          dataIndex: 'operation',
          key: 'operation',
          ellipsis: true,
          align:'center',
          scopedSlots: { customRender: 'operation' },
          width: 70
        },
      ],
      copyData:{},
      userdepidCascaderSelected: [],
      modalVisible: false,
      modalShowType: '',
      modalDetailData: null,

      //操作按钮权限
      btnList:[],
      menu:{},
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    ...mapGetters(['operationMenuTree']),
    pageTitle() {
      return "停车场台账"
    },
    breadcrumb() {
      const pages = [{name:"业务管理",path:""}]
      pages.push({name:"台账管理",path:""})
      pages.push({name:this.pageTitle,path:""})
      return pages
    },
  },
  watch:{
    userdepidCascaderSelected(val) {
      if(val && val.length) {
        this.queryParams.userdepsid = val[val.length-1];
      }else {
        this.queryParams.userdepsid = '';
      }
    },
  },
  created() {
    this.menu=getItemFromArrayByKey(this.operationMenuTree,"menuid",this.$route.params.menuid,"children")
    for(let i=0;i<this.menu.children.length;i++){//获取菜单页面内操作权限
      this.btnList.push(this.menu.children[i].menuname)
    }
    this.getTableData()
    this.initDeptOptionsAll();
  },
  methods:{
    getTableData(firstPage) {
      if(firstPage) {
        this.currentPage = 1;
      }
      this.tableLoading = true;
      let params = {
        ...this.queryParams,
        pageno: this.currentPage,
        pagesize: this.pagination.pageSize
      };
      getLedgerParkingByCondition(params).then(res => {
        this.tableLoading = false;
        if(res && res.returncode == '0') {
          this.tableData = res.item;
          this.pagination.total = res.count;
        }
      }).catch(err => {
        this.tableLoading = false;
      })
    },
    resetQueryParams() {
      this.userdepidCascaderSelected=[]
      this.$refs.queryForm.resetFields();
    },
    operationClick({key}){
      let arr = key.split('-');
      let type = arr[0];
      let ledger_id = arr[1];
      let record = getItemFromArrayByKey(this.tableData, 'ledger_id', ledger_id);
      if(type == 'delete') {
        this.deleteConfirm(ledger_id, record)
      }else if(type == 'copy'){
        this.copyData=record
        this.$message.success("数据已经复制完成")
      }else{
        this.showModal(type,record)
      }
    },
    showModal(type,record){
      if(type=='detail'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type=='edit'){
        this.modalDetailData=record
        this.modalShowType=type
        this.modalVisible=true
      }else if(type == 'paste') {
        this.modalDetailData = record
        this.modalShowType = type
        this.modalDetailData.btnList=this.btnList
        this.modalVisible = true
      }
    },
    addRecord(){
      this.modalVisible=true
      this.modalShowType='add'
    },
    exportModal(){
      this.$confirm('你确认要按照所选筛选条件导出文件吗？',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.exportBusDevice()
      }).catch(()=>{
      });
    },
    exportBusDevice(){
      let params = {
        ...this.queryParams,
        is_history:'0',
      }
      this.showLoading();
      exportBusDeviceExcel(params).then((res)=> {
        this.hideLoading();
        this.$message.success("导出成功")
      }).catch(()=>{
        this.hideLoading();
      })
    },
    deleteConfirm(value, record) {
      this.$confirm('确定要删除该数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(record.ledger_id);
      }).catch(()=>{

      }) ;
    },
    delete(ledger_id) {
      this.showLoading();
      if(ledger_id) {
        let params = {
          ledger_id
        };
        deleteLedgerParking(params).then(res => {
          this.hideLoading();
          if(res && res.returncode == '0') {
            this.$message.success('操作成功');
            this.getTableData();
          }else {
            this.$message.error(res.errormsg||'操作失败');
          }
        })
      }
    },
    deleteBatch() {
      if(this.batchrowids) {
        this.deleteBatchConfirm();
      }else {
        this.$message.warning('请先选择删除项');
      }
    },
    deleteBatchConfirm() {
      this.$confirm('确定要删除选中数据?',{
        type:'warning',
        centered:true,
      }).then(()=>{
        this.delete(this.batchrowids);
      }).catch(()=>{

      }) ;
    },
    rowSelectChange(selectedRowKeys, selectedRows) {
      this.batchrowids = selectedRowKeys.join('|');
    },
    customRow(record, index){
      return {
        on: { // 事件
          click: () => {
            this.modalDetailData=record
            this.modalShowType="detail"
            this.modalVisible=true
          },
        },
      }
    },
  },
}
</script>
<style scoped>

</style>