<template>
  <div>
    <a-modal v-model="modalVisible" :title="modalTitle" :width="1250" :centered="true" :body-style="modalBodyStyle">
      <template slot="footer">
        <a-button @click="modalVisible=false">{{showType=='detail' ? '关闭' : '取消'}}</a-button>
        <a-button v-if="showType=='add' || showType=='edit'|| showType=='paste'" type="primary" @click="confirm()">提交</a-button>
      </template>
      <a-form-model ref="modalForm" layout="inline" class="form-in-twocolumns" :model="formData" :rules="formRules" :label-col="{span:6}" :wrapper-col="{span:16}">
        <a-form-model-item label="项目名称" prop="monitorpointnum">
          <a-select :disabled="showType=='detail'" v-model="formData.monitorpointnum" placeholder="请选择" show-search :filter-option="filterOption">
            <a-select-option v-for="(item, index) in monitorpointList" :key="index" :value="item.monitorpointnum">{{item.monitorpointname}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="类别" prop="category">
          <a-select :disabled="showType=='detail'" v-model="formData.category">
            <a-select-option value="地下停车场">地下停车场</a-select-option>
            <a-select-option value="地面停车场">地面停车场</a-select-option>
            <a-select-option value="专用停车场">专用停车场</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="产权人" prop="property_owner">
          <a-select :disabled="showType=='detail'" v-model="formData.property_owner">
            <a-select-option value="开发商">开发商</a-select-option>
            <a-select-option value="企业业主">企业业主</a-select-option>
            <a-select-option value="个人业主">个人业主</a-select-option>
            <a-select-option value="全体业主共有">全体业主共有</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车位数" prop="parking_total">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.parking_total" />
        </a-form-model-item>
        <a-form-model-item label="车位使用形式" prop="parking_type">
          <a-select :disabled="showType=='detail'" v-model="formData.parking_type">
            <a-select-option value="自用车位">自用车位</a-select-option>
            <a-select-option value="固定车位">固定车位</a-select-option>
            <a-select-option value="月租车位">月租车位</a-select-option>
            <a-select-option value="临租车位">临租车位</a-select-option>
            <a-select-option value="免费车位">免费车位</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车位性质" prop="parking_nature">
          <a-select :disabled="showType=='detail'" v-model="formData.parking_nature">
            <a-select-option value="普通">普通</a-select-option>
            <a-select-option value="可充电">可充电</a-select-option>
            <a-select-option value="机械式">机械式</a-select-option>
            <a-select-option value="自动车库">自动车库</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="车位号" prop="parking_num">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.parking_num" />
        </a-form-model-item>
        <a-form-model-item label="车牌号" prop="license_plate">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.license_plate" />
        </a-form-model-item>
        <a-form-model-item label="联系人" prop="contacts">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.contacts" />
        </a-form-model-item>
        <a-form-model-item label="联系电话" prop="contacts_phone">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.contacts_phone" />
        </a-form-model-item>
        <a-form-model-item label="管理费标准" prop="management_fee_standard">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.management_fee_standard" suffix="元/月"/>
        </a-form-model-item>
        <a-form-model-item label="停车费标准" prop="parking_fee_standard">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.parking_fee_standard" />
        </a-form-model-item>
        <a-form-model-item label="月租金标准" prop="monthly_rent_standard">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.monthly_rent_standard" suffix="元/月"/>
        </a-form-model-item>
        <a-divider></a-divider>
        <a-form-model-item label="租金折扣" prop="rent_discount">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.rent_discount" />
        </a-form-model-item>
        <a-form-model-item label="实收租金" prop="paid_in_rent">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.paid_in_rent" suffix="元/月"/>
        </a-form-model-item>
        <a-form-model-item label="欠管理费金额" prop="management_fee_arrears">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.management_fee_arrears" suffix="元"/>
        </a-form-model-item>
        <a-form-model-item label="欠租金金额" prop="monthly_rent_arrears">
          <a-input :disabled="showType=='detail'" v-model.trim="formData.monthly_rent_arrears" suffix="元"/>
        </a-form-model-item>
        <a-form-model-item label="备注" prop="remark">
          <textarea :disabled="showType=='detail'" v-model.trim="formData.remark" style="width: 390px"/>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import moment from 'moment'
import {mapGetters, mapState} from 'vuex'
import areaselect from '@/mixins/areaselect'
import deptselect from '@/mixins/deptselect'
import {getMonitorPointNameListByCondition} from "A/monitoring";
import {addLedgerParking, getLedgerParkingByCondition, modifyLedgerParking} from "A/businessmanagement";
export default {
  mixins: [areaselect, deptselect],
  props: {
    visible: {
      default: false
    },
    showType: {
      default: 'add'
    },
    detailData: {
      default: null
    }
  },
  data() {
    return {
      moment,
      modalBodyStyle: {
        height: '480px',
        overflowY: 'auto',
      },
      modalVisible: false,
      formData: {
        monitorpointnum: '',
        category:'',
        property_owner:'',
        parking_total:'',
        parking_type:'',
        parking_nature:'',
        parking_num:'',
        license_plate:'',
        contacts:'',
        contacts_phone:'',
        management_fee_standard:'',
        parking_fee_standard:'',
        monthly_rent_standard:'',
        rent_discount:'',
        paid_in_rent:'',
        management_fee_arrears:'',
        monthly_rent_arrears:'',
        remark:'',
        create_time:"",
        creator:"",
      },
      //这里面的数据属于必填项
      formRules: {
        monitorpointnum: [{required: true, message: '请选择项目'}],
        category: [{required: true, message: '请选择类别'}],
        parking_total: [{required: true, message: '请输入车位数'}],
        property_owner: [{required: true, message: '请选择产权人'}],
        parking_type: [{required: true, message: '请选择车位使用类型'}],
        parking_nature: [{required: true, message: '请选择车位性质'}],
        parking_num: [{required: true, message: '请输入车位号'}],
        license_plate: [{required: true, message: '请输入车牌号'}],
        contacts: [{required: true, message: '请输入联系人'}],
        contacts_phone: [{required: true, message: '请输入联系电话'}],
        management_fee_standard: [{required: true, message: '请输入管理费标准'}],
        parking_fee_standard: [{required: true, message: '请输入停车费标准'}],
        monthly_rent_standard: [{required: true, message: '请输入月租金标准'}],
        rent_discount: [{required: true, message: '请输入租金折扣'}],
      },
      monitorpointList:[],
    }
  },
  computed: {
    ...mapState({
      userInfo: state => state.userInfo
    }),
    modalTitle() {
      if(this.showType == 'add') {
        return '新增'
      }else if(this.showType == 'edit'||this.showType == 'paste') {
        return '修改'
      }else if(this.showType == 'detail') {
        return '详情'
      }else {
        return '';
      }
    },
  },
  watch: {
    visible(val) {
      if(val) {
        this.modalVisible = true;
        this.resetData();
        this.getMonitorPointNameList();
        this.initDetail();
      }
    },
    modalVisible(val) {
      if(!val) {
        this.resetData();
        this.$emit('get-operation-result', 'success', '操作成功');
        this.$emit('update:visible', false);
      }
    },
  },
  created() {
    this.modalVisible = this.visible;
  },
  methods: {
    getMonitorPointNameList(){
      getMonitorPointNameListByCondition({userdepsid:this.userInfo.userdepid}).then(res=>{
        if(res&&res.returncode=='0'){
          this.monitorpointList=res.item
        }
      })
    },
    resetData() {
      if (this.$refs.modalForm) {
        this.$refs.modalForm.resetFields();
      }
    },
    initDetail() {
      if (this.detailData && this.detailData.ledger_id) {
        if(this.showType == 'edit' || this.showType == 'detail' || this.showType === 'paste') {
          this.$nextTick(() => {
            let params = {
              ledger_id: this.detailData.ledger_id
            }
            getLedgerParkingByCondition(params).then(res => {
              if (res && res.returncode == '0') {
                this.formData = res.item[0];
              }
            })
          })
        }
      }
    },
    confirm() {
      if (this.showType == 'add' || this.showType == 'edit' || this.showType === 'paste') {
        this.$refs.modalForm.validate(valid => {
          if (valid) {
            this.formData.create_time=moment(new Date()).format("YYYYMMDDHHmmss")
            this.formData.creator=this.userInfo.username
            let params = {
              ...this.formData,
            };
            if (this.showType == 'add' || this.showType === 'paste') {
              this.showLoading();
              addLedgerParking(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            } else if(this.showType == 'edit'){
              this.showLoading();
              modifyLedgerParking(params).then(res => {
                this.hideLoading();
                if (res && res.returncode == '0') {
                  this.$message.success('操作成功');
                  this.$emit('get-operation-result', 'success', '操作成功');
                  this.modalVisible = false;
                } else {
                  this.$message.error(res.errormsg || '操作失败');
                }
              })
            }else{
              this.$message.warning('请确认信息填写完整')
            }
          } else {
            this.$message.warning('请确认信息填写完整')
            return false;
          }
        })
      } else {
        this.modalVisible = false;
      }
    },
    filterOption(input, option) {
      return (
          option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  }
}
</script>
<style lang="scss" scoped>
.select-drop-down-platform {
  width: 240px;
}
</style>